@import "~bootstrap/scss/bootstrap";
@import './app.scss';
@import './common.scss';
@import './vendor.scss';
@import './contactUs.scss';
@import './navbar.scss';
@import './tools.scss';
@import './notFound.scss';
@import './tool.scss';
@import './search.scss';

@font-face {
  font-family: 'Al-Kanz';
  src: url('../fonts/Al-Kanz.ttf');
}

@font-face {
  font-family: 'Kanz-al-Marjaan';
  src: url('../fonts/kanz-al-marjaan.ttf');
}

.Al-Kanz {
  font-family: Al-Kanz, serif;
}

.Kanz-al-Marjaan {
  font-family: Kanz-al-Marjaan, serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #202634;
  color: $text-color
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
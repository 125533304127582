.contactUs {
    &__iconText {
        padding-left: 10px;
    }

    &__contactPersonals {
        &--arabicName {
            font-size: 1.25rem;
        }

        &--mobileNo {
            font-size: 0.9rem;
        }
    }
}
$tile-color: #293042;
$text-color: #FFFFFF;

.tiles {
    background-color: $tile-color;
}

.padding {
    @media (max-width: 992px) {
        padding-top: 15px;
    }
}

.center {
    text-align: center;
}

.nospacing {
    margin:0;
}

a {
    color: $text-color;
    &:hover {
        color: $text-color;
    }
}

.tableFixHead {
    overflow-y: auto;
    max-height: 345px;
}
.tableFixHead thead {
    position: sticky;
    top: 0;
    z-index: 10;
}
.tableFixHead th {
    background: #343a40;
}
.tableFixHead th {
    box-shadow: inset 1px 1px #454d55, 0 1px #454d55;
}
.navbar {
    background-color: $tile-color;
}

.table tbody td {
    text-align: center;
    vertical-align: middle;
}

.table tbody th {
    text-align: center;
    vertical-align: middle;
}

.table thead th {
    text-align: center;
    vertical-align: middle;
}
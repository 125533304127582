.Tools {
    padding: 10px;

    .tiles {
        height: 100%
    }

    :hover {
        transform: scale(1.002);
    }

    &__body {
        padding: 0;
    }
    
    &__name {
        padding: 10px 15px 10px 15px;

        &--category {
            padding: 10px 15px 5px 15px;
        }

        &--arabic {
            font-size: 1.2rem;
            text-align: right;
        }
    }

    a:hover {
        text-decoration: none;
    }
}
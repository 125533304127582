.search {
  margin-top: 15px;
  text-align: center;

  &__box {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid grey;
    color: white;
    font-size: 1.2rem;

    &:focus {
      outline: none;
      border-bottom: 1px solid white;
    }
  }

  &__result {
    margin: 15px auto;

    &Item {
      background-color: $tile-color;
      color: $text-color;

      &Name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        font-size: 1.3rem
      }

      &:hover {
        background-color: #1b202c;
        z-index: 10;
        text-decoration: none;
        transform: scale(1.1);
      }
    }
  }
}